import React from 'react';
import './App.css';
import { CssBaseline, Fab } from '@mui/material';
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import { DarkModeSwitch } from 'react-toggle-dark-mode';

const AppColorModeContext = React.createContext({ toggleColorMode: () => {} });

function App() {
  const theme = useTheme();

  return(
    <div>
      <main>{theme.palette.mode} mode</main>
    </div>
  )
}

function AppInit() {
  const [mode, setMode] = React.useState();

  React.useEffect(() => {
    const data = window.localStorage.getItem('APP_COLOR_MODE');
    const preferredMode = (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) ? "dark" : "light";
    setMode(data !== null ? data : preferredMode);
  }, []);

  React.useEffect(() => {
    if(mode === undefined) return;
    window.localStorage.setItem('APP_COLOR_MODE', mode);
  }, [mode]);

  const theme = React.useMemo(() => createTheme({palette: { mode }}), [mode]);
  const colorMode = React.useMemo(() => ({
    toggleColorMode: () => {
      setMode((prevMode) => ((prevMode === 'light' || prevMode === undefined) ? 'dark' : 'light'));
    }}),
    []
  );

  window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
    const newColorScheme = event.matches ? "dark" : "light";
    setMode((prevMode) => newColorScheme);
  });

  return (
    <AppColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />        
        <App />
        <Fab color="primary" variant="extended" onClick={() => { colorMode.toggleColorMode(); }} sx={{ position: 'fixed', bottom: 16, right: 16 }}>
          <DarkModeSwitch checked={mode === "dark"} onChange={ () => { } }/>
        </Fab>
      </ThemeProvider>
    </AppColorModeContext.Provider>
  )
}

export default AppInit;
